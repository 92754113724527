<template>
  <div class="jurisdiction">
    <!-- 搜索区域 -->
    <div class="header">
      <el-input class="input" v-model="input" placeholder="请输入权限名称"></el-input>
      <el-button type="primary" @click="searchInp">查询</el-button>
    </div>
    <!-- 表格区域 -->
    <div class="wrapper">
      <el-table :data="tableData" v-loading="loading" style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="jurisdiction_name" label="权限名称" width="300"></el-table-column>
        <el-table-column prop="chain_name" label="机构名称" width="400"></el-table-column>
        <el-table-column prop="created" label="创建时间"></el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row)">配置权限</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getjurisdiction } from '../../../request/api'

export default {
  data () {
    return {
      input: '',
      tableData: [],
      loading: false
    }
  },
  methods: {
    // 搜索按钮
    searchInp () {
      this.tableData = []
      this.getJurisdictionList()
    },
    handleEdit (row) {
      this.$router.push(
        {
          path: '/user/jurisdictionDetails',
          query: {
            shop_jurisdiction_add_id: row.shop_jurisdiction_add_id
          }
        }
      )
    },
    // 获取权限列表
    async getJurisdictionList () {
      try {
        this.loading = true
        const res = await getjurisdiction(this.input)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        this.tableData = (res.data.data.jurisdictionList)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.getJurisdictionList()
  }
}
</script>

<style lang="less" scoped>
.jurisdiction {
  margin: 20px;
  .header {
    display: flex;
    .input {
      width: 240px;
    }
    button {
      margin-left: 10px;
    }
  }
  .wrapper {
    margin: 20px 0;
  }
}
</style>